import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hide", "filter"];

  toggle() {
    this.hideTargets.forEach((t) => t.classList.toggle(this.data.get("class")));
  }

  activate(event) {
    this.filterTargets.forEach((t) => t.classList.remove("active"));
    event.target.classList.add("active");
    let full_url= new URL(event.target.closest("a").href);
    history.pushState(event.target.href, "",full_url);
  }
}
